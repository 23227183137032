import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const HomePage = () => {
  const router = useRouter();

  useEffect(() => {
    router.push('/theos-service-of-blessing/info');
  }, []);

  return <></>;
};
